<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: 100vh;" @click="handleClear">
      <el-row type="flex">
        <el-col :span="12" :offset="1">
          <PageHeader :title="__('Campaign Data')"></PageHeader>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="22" :offset="1">
          <el-form class="form-container">
            <PaginationToolbar
              :sort-by-options="sortByOptions"
              :content-api="contentAPI"
              :auto-refresh-content="true"
              :refresh-interval="5000"
              :key="`view-data-${campaign.campaign_id}`"
            >
            </PaginationToolbar>
          </el-form>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="22" :offset="1">
          <div class="contentListContainer" v-loading="loading">
            <el-table
              ref="campaignsDataTable"
              class="list-table"
              :data="campaign_data"
              highlight-current-row
              row-key="campaign_data_id"
              @row-click="handleSingleClick"
              @row-dblclick="handleDoubleClick"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <h3>{{ __("Passed Parameters") }}</h3>
                  <div
                    v-if="props.row.campaign_data_passed_params.data.length > 0"
                  >
                    <p
                      v-for="passed_param in props.row
                        .campaign_data_passed_params.data"
                      style="margin-left: 10px"
                      v-bind:key="passed_param.param_name"
                    >
                      <b>{{ passed_param.param_name }}</b
                      >:
                      {{ passed_param.param_value }}
                    </p>
                  </div>
                  <div v-else>
                    <p style="margin-left: 10px">{{ __("None") }}</p>
                  </div>
                  <div v-if="props.row.reason">
                    <h3>{{ __("Error Reason") }}</h3>
                    <p>{{ props.row.reason }}</p>
                  </div>
                  <div v-if="props.row.call_channel_id">
                    <h3>{{ __("Call Channel ID") }}</h3>
                    <p style="margin-left: 10px">
                      {{ props.row.call_channel_id }}
                    </p>
                  </div>
                  <div v-if="props.row.outbound_call_uuid">
                    <h3>{{ __("Outbound Call UUID") }}</h3>
                    <p style="margin-left: 10px">
                      {{ props.row.outbound_call_uuid }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="campaign.campaign_channel !== 'chatbot'"
                :label="__('Phone Number')"
                prop="phone_number"
              >
              </el-table-column>
              <el-table-column
                v-else
                :label="__('Session Id')"
                prop="phone_number"
              >
              </el-table-column>
              <el-table-column :label="__('Call Status')" min-width="150">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <div :style="getStatusStyles(scope.row.call_status)"></div>
                    {{ capitalize(scope.row.call_status) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="__('Uploaded At')">
                <template slot-scope="scope">
                  <el-tooltip
                    :content="formattedDateTime(scope.row.created_at)"
                    placement="top"
                  >
                    <span>
                      {{ createdAt(scope.row.created_at) }}
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                :label="__('Number Of Retries')"
                prop="retry_count"
              />
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-scrollbar>
</template>

<script>
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapGetters, mapState } from "vuex";
import { formatTimeStampAsCalendar } from "@/utils/time";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import moment from "moment-timezone";

export default {
  mixins: [BaseContent],
  name: "viewData",
  components: { PageHeader, PaginationToolbar },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewData: false,
      now: moment().format(),
      sortByColumns: ["phone_number", "campaign_data_id"]
    };
  },
  methods: {
    ...mapActions("campaigns", {
      contentAPI: "getCampaignData"
    }),
    capitalize(label) {
      let labelParts = label.split("_");
      let capitalizedLabel = [];

      for (let x = 0; x < labelParts.length; x++) {
        capitalizedLabel.push(
          labelParts[x].charAt(0).toUpperCase() + labelParts[x].slice(1)
        );
      }
      return capitalizedLabel.join(" ");
    },

    getStatusStyles(status) {
      let statusStyles = {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-right": "10px",
        border: "3px solid"
      };

      const statusColorMap = {
        pending: "#C0F6E8",
        processing: "#4AEB8E",
        connecting: "#04B989",
        ringing: "#1CD1A1",
        answered: "#51B848",
        not_answered: "#BE1E2A",
        answering_machine: "#BE1E2A",
        dial_timeout: "#F84856",
        busy: "#FAB800",
        error: "#F84856",
        sent: "#51B848",
        failed: "#F84856"
      };

      statusStyles["border-color"] = statusColorMap[status] || "#1CD1A1";
      return statusStyles;
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
    },
    handleClear() {
      this.$refs.campaignsDataTable &&
        this.$refs.campaignsDataTable.setCurrentRow();
    }
  },
  computed: {
    ...mapState("campaigns", {
      loading: state => state.loading,
      campaign_data: state => state.campaign_data
    }),
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime",
      timezone: "timezone"
    }),
    createdAt() {
      return createdAt => {
        if (this.now) {
          return formatTimeStampAsCalendar(createdAt / 1000, this.timezone);
        }
      };
    }
    // shouldRefresh() {
    //   return (
    //     this.campaign.campaign_type.toLowerCase() === "on-demand" ||
    //     (this.campaign.campaign_type.toLowerCase() === "list-based" &&
    //       this.campaign.campaign_status.toLowerCase() === "running")
    //   );
    // }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
